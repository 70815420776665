/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: AliRegular;
    src: url('./assets/fonts/AlibabaPuHuiTi-2-55-Regular.otf');
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: AliHeavy;
    src: url('./assets/fonts/AlibabaPuHuiTi-2-85-Bold.otf');
    font-weight: 700;
    font-display: swap;
}

p{
    line-height: 110%;
}

html,
body {
    font-size: 16px;
    font-family: AliRegular, AliHeavy;
}

@media screen and (min-width: 1200px) {
    html {
        font-size: 32px;
    }
}

body {
    margin: 0;
    // font-family: Roboto, "Helvetica Neue", sans-serif;
}

.my-custom-panel-class {
    background-color: gray;
    width: 200px;
    height: 200px;
}

.font-size-large {
    font-size: large;
}

.flex {
    display: flex;
}

.vertical-center {
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.white {
    color: #fff;
}

.black {
    color: #000;
}

$step: 1; // step size
$limit: 100; // upper limit

// Loop to create padding classes
@for $i from 0 through $limit {
    .padding-#{$i * $step} {
        padding: $i * $step * 1%;
    }
}

@for $i from 0 through $limit {
    .flex-#{$i * $step} {
        flex: $i * $step;
    }
}

.english-font {
    font-family: 'Arial', sans-serif;
}

.chinese-font {
    font-family: 'SimHei', serif;
}

.no-select {
    user-select: none; /* 对于大多数现代浏览器 */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}